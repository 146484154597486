<template>
  <AdminLayout
    title="Settings"
  >
    <v-form
      @submit.prevent="updateSetting(registration_key)"
    >
      <v-text-field
        :label="registration_key.name"
        :value="registration_key.value"
        v-model="registration_key.value"
      >
      </v-text-field>
      <v-btn
        type="submit"
        color="primary"
      >
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </v-form>
  </AdminLayout>
</template>

<script>
import AdminLayout from '../../layouts/AdminLayout'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Settings',

  async created () {
    await this.fetchSettings()
  },

  components: {
    AdminLayout,
  },

  computed: {
    ...mapGetters('settings', ['registration_key']),
  },

  methods: {
    ...mapActions('settings', ['fetchSettings', 'updateSetting']),
  },
}
</script>
